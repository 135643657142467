function store(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function retrieve(key) {
  return JSON.parse(localStorage.getItem(key));
}

function addClass(el, className) {
  if (el.classList)
    el.classList.add(className);
  else
    el.className += ' ' + className;
}

function removeClass(el, className) {
  if (el.classList)
    el.classList.remove(className);
  else
    el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}

function queryParams() {
  var queryString = window.location.search.slice(1);

  return queryString
    .split('&')
    .map(param => param.split('='))
    .reduce((params, [key, value]) => {
      params[key] = value !== undefined ? value : true;
      return params;
    }, {});
}

function createElement(htmlStr) {
  let temp = document.createElement('div');
  temp.innerHTML = htmlStr;
  return temp.firstElementChild;
}

function addDirectEventListener(element, event, callback, ...options) {
  element.addEventListener(event, (e) => {
    if (e.target === element) callback(e);
  }, ...options);
}

function combineDateTime(date, time) {
  if (!moment.isMoment(date))
    date = moment(date);

  if (!moment.isMoment(time))
    time = moment(time);

  return moment(
    `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`,
    'YYYY-MM-DD HH:mm'
  );
}

function removeChildren(parent) {
  while (parent.lastChild)
    parent.removeChild(parent.lastChild);
}
